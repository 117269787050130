
import React from 'react'
import { Col, Row } from 'reactstrap';
import ValidationField from '../../../Components/ValidationField/ValidationField';
import { useFormikContext } from 'formik';

import { useTranslation } from 'react-i18next';
import { langauge_field_genrater } from '../../../Hooks/useLanguageGenrater';
import { useGetService } from '../../../api/Service';
import useFormatToSelect from '../../../Hooks/useFormatToSelect';
import {Nationalities} from '../../../Data/nationality'
import { Languages } from '../../../Data/languages';
function DescriptionForm() {   
  return (
  <div>
    {langauge_field_genrater(["description"],"Editer")}
  </div>
  )
}

export default DescriptionForm


