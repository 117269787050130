import { Form } from 'antd';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import useFormField from '../../../Hooks/useFormField';

const Editer = ({ name, label, placeholder, isDisabled, props, label2 }: any) => {
  const { formik, isError, errorMsg, t } = useFormField(name, props);

  const last_name = label2 ? t(label) + ' ' + t(label2) : t(`${label ? label : name}`);
    console.log(formik.values[name]);
    
  return (
    <div className="ValidationField w-100">
      <label htmlFor={name} className="text">
        {last_name}
      </label>
      <Form.Item
        hasFeedback
        validateStatus={isError ? 'error' : ''}
        help={isError ? errorMsg : ''}
      >
        <ReactQuill
          theme="snow"
          value={formik.values[name]} // Correctly bind the value
          onChange={(content) => formik.setFieldValue(name, content)} // Update Formik's value
          placeholder={t(`${placeholder ? placeholder : last_name}`)}
          readOnly={isDisabled}
          style={{ height: '200px', minHeight: '200px' , marginBottom: 40}} 
          
        />
      </Form.Item>
    </div>
  );
};

export default React.memo(Editer);
