
import useAddMutation from "./helper/useAddMutation"
import useDeleteMutation from "./helper/useDeleteMutation"
import useGetQuery from "./helper/useGetQuery"
import useUpdateMutation from "./helper/useUpdateMutation"

const API = {
  GET: "/how_it_work/getAll",
  ADD: "/how_it_work/add",
  UPDATE: "/how_it_work/update",
  DELETE: "/how_it_work/delete",
};

const KEY = "HOW_IT_WORK";
export const useGetHowItWork = (params?: any) => useGetQuery(KEY, API.GET, params);
export const useAddHowItWork = () => useAddMutation(KEY, API.ADD);
export const useUpdateHowItWork = () => useUpdateMutation(KEY, API.UPDATE);
export const useDeleteHowItWork = () => useDeleteMutation(KEY, API.DELETE);
